import { IExam } from 'modules/Exam'
import { getMainDomain } from '.'

export const getSubjectUrl = (
  exam: IExam | null,
  subjectName?: string
): string => {
  const isCT = exam?.name === 'ct'
  const isOLYMP = false
  const isEGE = false //exam?.name === 'ege'
  const isOGE = false //exam?.name === 'oge'
  const isVPR = false //exam?.name === 'vpr'
  const isNMT = exam?.name === 'nmt'
  const isZNO = exam?.name === 'zno'
  const isENT = exam?.name === 'ent'
  const isUROK = false //exam?.name === 'urok'
  const mainDomain = getMainDomain(exam?.domain, isCT, isEGE, isOGE, isVPR, isOLYMP, isZNO, isENT, isUROK)
  const separator = isZNO || isENT || isCT || isEGE || isOGE || isVPR || isNMT || isUROK || (isOLYMP && exam?.domain?.indexOf('reshuolymp.ru') !== -1)? '.' : '-'

  return `https://${subjectName}${separator}${mainDomain}`
}
